import React from 'react';

import Container from 'components/molecules/Container';
import PhotoGallery, { PhotoGalleryProps } from 'components/molecules/PhotoGallery';
import Banner, { BannerVariant } from 'components/organisms/Banner';
import { ContentElementType } from 'components/templates/ContentElement';

import { StyledContainer, StyledContentElement } from './CaseStudyDetailPage.styles';

import { CaseStudyContentTypes, CaseStudyDetailPageType } from './models.d';

const CaseStudyDetailPage = ({ content, banner }: CaseStudyDetailPageType) => {
  const renderElement = (el: CaseStudyContentTypes, idx: number) => {
    if (!el.internal) return null;

    const components = {
      ContentfulContentElement: (
        <StyledContentElement {...(el as ContentElementType)} variant="case-study" />
      ),
      ContentfulCarouselStructure: (
        <Container contentWidth={1280}>
          <PhotoGallery {...(el as PhotoGalleryProps)} />
        </Container>
      ),
    };

    const element = components[el.internal.type] || null;

    return (
      <Container
        key={`${el.variant}-${idx}`}
        mt={{ base: 28, md: 48 }}
        mb={
          el.internal.type === 'ContentfulContentElement' && (el as ContentElementType).technologies
            ? undefined
            : { base: 28, md: 48 }
        }
      >
        {element}
      </Container>
    );
  };

  return (
    <StyledContainer
      contentWidth={1920}
      hasContent={Boolean(banner || content)}
      pb={{ base: 48, md: 60, lg: 120 }}
    >
      {banner ? <Banner {...banner} variant={BannerVariant.CASE_STUDY} /> : null}
      {content ? content.map((el, idx) => renderElement(el, idx)) : null}
    </StyledContainer>
  );
};

export default CaseStudyDetailPage;
