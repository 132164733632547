import React, { useEffect, useRef } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import kebabCase from 'lodash/kebabCase';

import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';
import TechnologiesSection, {
  TechnologiesSectionVariant,
} from 'components/organisms/TechnologiesSection';

import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

import { imageDescriptionOptions, options } from './ContentElement.constants';
import {
  StyledContainer,
  StyledContentWrapper,
  StyledImageDescription,
} from './ContentElement.styles';

import { ContentElementType } from './models.d';

const ContentElement = ({
  className,
  title,
  description,
  contentImg,
  imageDescription,
  technologies,
  variant = 'blog',
  handleChangeSection,
}: ContentElementType) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: false,
    rootMargin: '-30% 0px -70% 0px',
  });

  useEffect(() => {
    if (entry?.isIntersecting && handleChangeSection) {
      handleChangeSection(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry]);

  return (
    <StyledContentWrapper {...{ className, ref, variant }}>
      <StyledContainer contentWidth={variant === 'case-study' ? 740 : undefined}>
        {title ? (
          <Typography
            asEl="h2"
            id={/^\d/.test(title) ? `id-${kebabCase(title)}` : `${kebabCase(title)}`}
            size={{ base: 24, lg: 36, xl: 48 }}
            lineHeight={1.2}
            color="gray-800"
            letterSpacing="-0.02em"
            padding={{ bottom: 24 }}
          >
            {title}
          </Typography>
        ) : null}
        {description ? (
          <Typography lineHeight={1.5} size={{ base: 16, lg: 18 }} color="gray-800">
            {renderRichText(description, options)}
          </Typography>
        ) : null}
        {technologies ? (
          <TechnologiesSection {...technologies} variant={TechnologiesSectionVariant.CASESTUDY} />
        ) : null}
      </StyledContainer>
      {contentImg ? (
        <Container noPadding>
          <Image {...contentImg} className={`content-image  content-image--${variant}`} />
          {imageDescription ? (
            <StyledImageDescription className="image-description">
              {renderRichText(imageDescription, imageDescriptionOptions)}
            </StyledImageDescription>
          ) : null}
        </Container>
      ) : null}
    </StyledContentWrapper>
  );
};

export default ContentElement;
