import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';

import Link from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';

import { StyledListElement } from './ContentElement.styles';

export const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Typography asEl="p" fontWeight={400} size={{ base: 16, lg: 20 }} color="gray-800">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Typography size={{ base: 18, lg: 20 }} fontWeight={400} color="gray-800" asEl="h3">
        {children}
      </Typography>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => <StyledListElement>{children}</StyledListElement>,
  },
  renderMark: {
    [MARKS.CODE]: (children) => <Typography padding={{ left: 10 }}>{children}</Typography>,
  },
};

export const imageDescriptionOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Typography asEl="span" fontWeight={400} size={16} padding={{ top: 16 }} color="gray-600">
        {children}
      </Typography>
    ),
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <Link typography={{ fontWeight: 500 }} target="_blank" to={data.uri}>
        &nbsp;
        {children}
      </Link>
    ),
  },
};
