import styled, { css } from 'styled-components';
import { breakpoint } from 'styles';

import Link from 'components/atoms/Link';
import Container from 'components/molecules/Container';

export const StyledContainer = styled(Container)`
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index-zero);
    width: 100%;
    height: 905px;
    clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
    content: '';
    background: var(--c-white);
  }
`;

export const StyledWrapper = styled.div`
  z-index: var(--z-index-ground);
  padding: 0 0 var(--gap-xs);

  ${breakpoint.lg} {
    display: grid;
    grid-template-columns: 228px 1fr;
    column-gap: var(--gap-lg);
    align-items: start;
    padding: var(--gap-xxs) 0;
  }

  ${breakpoint.xl} {
    column-gap: var(--gap-xxxxxl);
  }
`;

export const StyledLink = styled(Link)<{ active?: boolean } & typeof Link>`
  ${({ active }) =>
    active
      ? css`
          color: var(--c-gray-900);

          &::before {
            content: '• ';
          }
        `
      : ''}
`;

export const StyledNavWrapper = styled.div`
  position: relative;
  z-index: var(--z-index-two);
  width: 100%;
  max-width: 520px;
  margin: 0 auto;

  ${breakpoint.lg} {
    position: sticky;
    top: calc(var(--header-height-desktop) + var(--gap-sm));
    left: 0;
  }
`;

export const StyledNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: var(--gap-xxs);
  max-width: 332px;
  padding: var(--gap-xxs);
  margin-bottom: var(--gap-lg);

  ${breakpoint.md} {
    margin-bottom: var(--gap-xxxxxl);
  }

  ${breakpoint.lg} {
    width: 100%;
  }

  ${breakpoint.xl} {
    padding: var(--gap-sm);
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: calc(-1 * var(--gap-xxxxxl));
`;
