import styled, { css } from 'styled-components';
import { breakpoint } from 'styles';

import Typography, { StyledTypography } from 'components/atoms/Typography';
import Container from 'components/molecules/Container';
import { StyledTechnologiesSection } from 'components/organisms/TechnologiesSection';

import { ContentElementType } from './models.d';

export const StyledImageDescription = styled(Typography)``;

export const StyledContentWrapper = styled.div<{ variant: ContentElementType['variant'] }>`
  position: relative;

  ${({ variant }) =>
    variant === 'blog' &&
    css`
      h2::before {
        display: block;
        height: calc(2 * var(--gap-xxxxxl));
        margin-top: calc(-1 * var(--gap-xxxxxl));
        pointer-events: none;
        content: '';
      }
    `}

  ${StyledTypography} {
    position: relative;
    width: 100%;
    max-width: 520px;
    margin: 0 auto;

    p {
      margin-bottom: 20px;
    }

    b {
      font-weight: 600;
    }

    a {
      text-decoration: underline;
    }

    ${breakpoint.lg} {
      max-width: unset;
    }
  }

  ${StyledImageDescription} {
    margin-left: 0;
  }

  .image-description {
    justify-content: flex-start;
  }

  ${StyledTechnologiesSection} {
    margin-top: var(--gap-lg);
  }

  .content-image {
    width: 100%;
    margin-top: var(--gap-xl);

    ${breakpoint.md} {
      margin-top: 96px;
    }

    &--case-study {
      display: block;
      max-width: var(--wrapper-medium);
      height: 180px;
      margin-right: auto;
      margin-left: auto;

      ${breakpoint.md} {
        height: 429px;
      }
    }

    &--blog {
      ${breakpoint.lg} {
        width: auto;
      }
    }

    & + ${StyledTypography} ${StyledTypography} {
      flex-direction: row;
    }
  }
`;

export const StyledListElement = styled.li`
  display: flex;
  margin: var(--gap-sm) 0;

  &::before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    padding-bottom: 3px;
    margin-right: var(--gap-xxxs);
    content: '•';
    background-color: var(--c-white);
    border-radius: 50%;
  }
`;

export const StyledContainer = styled(Container)`
  padding: 0;
`;
