import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import kebabCase from 'lodash/kebabCase';

import Typography from 'components/atoms/Typography';
import Banner from 'components/organisms/Banner';
import ContentElement from 'components/templates/ContentElement';

import {
  StyledContainer,
  StyledContent,
  StyledLink,
  StyledNavigation,
  StyledNavWrapper,
  StyledWrapper,
} from './BlogPostPage.styles';

import { BlogPostPageType } from './models.d';

const BlogPostPage = ({ content, banner }: BlogPostPageType) => {
  const location = useLocation();

  const [actualSection, setActualSection] = useState('');

  const getQueryParam = () => {
    const { hash } = location;
    setActualSection(hash.replace('#', ''));
  };

  const handleChangeSection = (name: string) => {
    if (name !== actualSection) {
      setActualSection(kebabCase(name));
    }
  };

  useEffect(() => {
    getQueryParam();

    return () => setActualSection('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const links = content.map(({ title }) =>
    title ? (
      <StyledLink
        key={title}
        to={/^\d/.test(title) ? `#id-${kebabCase(title)}` : `#${kebabCase(title)}`}
        onClick={() => setActualSection(kebabCase(title))}
        typography={{
          padding: { top: 14 },
          asEl: 'span',
          size: 16,
          fontWeight: 400,
          color: 'gray-600',
          lineHeight: 1.5,
        }}
        active={actualSection === kebabCase(title)}
      >
        {title}
      </StyledLink>
    ) : null
  );

  return (
    <StyledContainer contentWidth={1280}>
      {banner ? <Banner {...banner} /> : null}
      <StyledWrapper>
        <StyledNavWrapper>
          <StyledNavigation>
            <Typography
              asEl="h2"
              size={20}
              fontWeight={500}
              color="gray-900"
              lineHeight={1.5}
              padding={{ bottom: 2 }}
            >
              Table of content
            </Typography>
            {links}
          </StyledNavigation>
        </StyledNavWrapper>
        <StyledContent>
          {content.map((el) => (
            <ContentElement key={el.id} {...{ ...el, handleChangeSection }} />
          ))}
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default BlogPostPage;
