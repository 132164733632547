import styled from 'styled-components';

import Container from 'components/molecules/Container';
import ContentElement from 'components/templates/ContentElement';

export const StyledContentElement = styled(ContentElement)`
  padding: 0 var(--gap-xs);
`;

export const StyledContainer = styled(Container)<{ hasContent: boolean }>`
  position: relative;
`;
