import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Container from 'components/molecules/Container';
import RelatedList, { RelatedListVariant } from 'components/organisms/RelatedList';
import BlogPostPage from 'components/templates/BlogPostPage';
import CaseStudyDetailPage from 'components/templates/CaseStudyDetailPage';
import { StyledCSRelatedListContainer } from 'components/templates/containers';
import { ContentElementType } from 'components/templates/ContentElement';
import Layout from 'components/templates/Layout';

const CaseStudiesDetailPage = ({
  data: {
    contentfulCaseStudiesDetailPage: {
      variant,
      banner,
      content,
      relatedList,
      relatedBlogPosts,
      seo,
    },
  },
}: CaseStudiesDetailPageTypes.CaseStudiesDetailPagePageProps) => (
  <Layout
    seo={seo}
    className={classNames({ 'case-study-page': variant === 'blog' })}
    transparentHeader={variant === 'case-studies'}
  >
    {variant === 'blog' ? (
      <BlogPostPage content={content as ContentElementType[]} {...{ banner }} />
    ) : (
      <CaseStudyDetailPage {...{ banner, content }} />
    )}
    {relatedBlogPosts && relatedBlogPosts.relatedSites ? (
      <Container
        mt={{ base: 80, md: 136, lg: 176, xl: 200 }}
        pb={{ base: 80, md: 136, lg: 176, xl: 200 }}
      >
        <RelatedList
          list={relatedBlogPosts.relatedSites}
          proportions={relatedBlogPosts.proportions}
          button={relatedBlogPosts.button?.[0]}
          heading={relatedBlogPosts.title}
          description={relatedBlogPosts.description}
          variant={RelatedListVariant.SECONDARY_REVERSE}
        />
      </Container>
    ) : null}
    {relatedList && relatedList.relatedSites ? (
      <StyledCSRelatedListContainer pb={{ base: 80, md: 96, lg: 136, xl: 160 }}>
        <RelatedList
          list={relatedList.relatedSites}
          proportions={relatedList.proportions}
          button={relatedList.button?.[0]}
          heading={relatedList.title}
          variant={RelatedListVariant.SECONDARY}
          addExtraPadding
        />
      </StyledCSRelatedListContainer>
    ) : null}
  </Layout>
);

export default CaseStudiesDetailPage;

export const query = graphql`
  query CaseStudiesDetailPageQuery($slug: String) {
    contentfulCaseStudiesDetailPage(slug: { eq: $slug }) {
      seo {
        ...SeoFragment
      }
      pageName
      variant
      slug
      banner {
        ...BannerFragment
      }
      content {
        ... on ContentfulContentElement {
          id
          title
          description {
            raw
          }
          imageDescription {
            raw
          }
          contentImg: image {
            ...ImageDataStructureFragment
          }
          internal {
            type
          }
          technologies {
            ...TechnologiesSectionFragment
          }
        }
        ... on ContentfulCarouselStructure {
          id
          internal {
            type
          }
          ...CarouselStructureFragment
        }
      }
      relatedList {
        ...RelatedListFragment
      }
      relatedBlogPosts {
        ...RelatedListFragment
      }
    }
  }
`;
